$c-white-1: #FFFFFF;
$c-white-2: #F7F8FC;
$c-white-3: #FCFCFC;
$c-white-4: #F2F2F2;
$c-white-5: #FAFAFA;
$c-white-6: #F9F9F9;
$c-white-7: #F1F5F5;
$c-white-8: #F8F8F8;
$c-white-9: #F1F1F1;
$c-white-10: #E9EDED;

$c-black-1: #3D3F52;
$c-black-2: #2e303f;
$c-black-3: #2e3a59;
$c-black-4: #869B9B;
$c-black-5: #9FB4B4;
$c-black-6: #323A63;
$c-black-7: #9FA2B4;
$c-black-8: #25253B;
$c-black-9: #000000;
$c-black-10: #E1EBEB;
$c-black-11: #E6E6E6;
$c-black-12: #657B7B;
$c-black-13: #506161;
$c-black-14: #2e3859;
$c-black-15: #343434;

$c-red-1: #A63625;
$c-red-2: #d63e3e;
$c-red-3: #DC3422;
$c-red-4: #F80A0A;
$c-red-5: #FF5532;
$c-red-6: #B1009E;
$c-red-7: #fff7f5;
$c-red-8: #621616;
$c-red-9: #facccc;

$c-blue-1: #1A76BC;
$c-blue-2: #199EFE;
$c-blue-3: #2DB2FF;
$c-blue-4: #058AEA;
$c-blue-5: #4285F4;
$c-blue-6: #004166;
$c-blue-7: #bee7ff;

$c-gray-1: #9fa2b4;
$c-gray-2: #D4D5D6;
$c-gray-3: #E5E5E5;
$c-gray-4: #616161;
$c-gray-5: #EFEFEF;
$c-gray-6: #DFE0EB;
$c-gray-7: #F1F5F7;
$c-gray-8: #E5ECF0;
$c-gray-9: #BCBDC1;
$c-gray-10: #BBBCBD;
$c-gray-11: #E5ECF0;
$c-gray-12: #d4d4d4;
$c-gray-13: #CDDBDE;
$c-gray-14: #9FA2B4;
$c-gray-15: #AAAAAA;
$c-gray-16: #CCCCCC;
$c-gray-17: #C4C4C4;
$c-gray-18: #DFDFDF;
$c-gray-19: #EEEEEE;
$c-gray-20: #7C8099;
$c-gray-21: #5F6279;
$c-gray-22: #D2DEE5;
$c-gray-23: #ADADAD;
$c-gray-24: #EAEAEA;
$c-gray-25: #c5d2d2;
$c-gray-26: #7B7B7B;
$c-gray-27: #EBEBEB;
$c-gray-28: #E2E9E9;
$c-gray-29: #F4F4F4;

$c-green-1: #B2D9B3;
$c-green-2: #7BB77E;
$c-green-3: #A5D2A7;
$c-green-4: #4DA54D;
$c-green-5: #339933;
$c-green-6: #4EA64C;
$c-green-7: #178D19;
$c-green-8: #65B366;
$c-green-9: #99CC98;
$c-green-10: #869B9B;
$c-green-11: #9FB4B4;
$c-green-12: #2ED573;
$c-green-13: #DDEEDD;
$c-green-14: #4D9C4E;
$c-green-15: #458D46;
$c-green-16: #61914B;
$c-green-17: #f6fff6;
$c-green-18: #e5f2e5;


$c-orange-1: #FDAC2B;
$c-orange-2: #F29F05;
$c-orange-3: #F0A821;
$c-orange-4: #FFE6B0;
$c-orange-5: #F6A900;
$c-orange-6: #E69D00;
$c-orange-7: #FED17B;
$c-orange-8: #A06201;
$c-orange-9: #FED594;
$c-orange-10: #FFF7E6;
$c-orange-11: #fff9ef;
$c-orange-12: #fff2de;
$c-orange-13: #feb646;

$p-black: #101010;
$p-white: #FFFFFF;

$p-grey-50: #ECECEC;
$p-grey-100: #D7D7D7;
$p-grey-200: #C1C1C1;
$p-grey-300: #A6A7A7;
$p-grey-400: #9A9A9A;
$p-grey-500: #C1C1C1;
$p-grey-600: #505050;
$p-grey-700: #4A4A4A;
$p-grey-800: #343434;
$p-grey-900: #232323;
$p-grey-1000: #1D1D1D;

$p-orange-400: #CE6230;
$p-orange-600: #994924;

$p-green-200: #7FDCA4;
$p-green-300: #05C168;
$p-green-800: #035931;


$p-red-400: #DC2B2B;
$p-red-500: #AA1F1F;

$p-purple-200: #7D6DC2;
$p-purple-300: #6e5faf;
$p-purple-400: #554891;
$p-purple-800: #2f2659;

$p-blue-100: #6e79d7;
$p-blue-300: #3F4DCE;
$p-blue-400: #2836B4;
$p-blue-800: #15218a;

$p-yellow-300: #FDC910;
$p-yellow-800: #564A0A;