* {
  box-sizing: border-box;
}
body {
  font-family: $font-family;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  color: $c-text;

  ::-webkit-scrollbar {
    width: 9px;
  }

  ::-webkit-scrollbar-track {
    background: #191A1A;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: #343434;
    border: 1px solid #343434;
    box-sizing: border-box;
    border-radius: 30px;
  }
}
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}
a {
  outline: none;
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
img {
  display: block;
  height: auto;
  width: 100%;
}
