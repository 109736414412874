.popup-content {
  width: 0;
  height: 0;
}

.popup-overlay .popup-content {
  width: auto;
  height: auto;
}

.tooltip-alert {
  width: max-content;
  position: absolute;
  left: 0;
  top: 0;
}

.alert {
  position: relative;
  background: $c-white-6;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}
