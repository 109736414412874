.separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $p-grey-600;

  &::before,
  &::after {
    display: flex;
    flex-direction: row;
    align-items: center;
    content: '';
    height: 1px;
    width: 100%;
    background-color: $p-grey-600;
  }

  &::before {
    margin-right: 6px;
  }

  &::after {
    margin-left: 6px;
  }
}