$font-family: Space Grotesk, Work Sans, Helvetica, Arial, sans-serif;

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('~assets/fonts/work-sans-v8-latin-regular.woff2') format('woff2'),
  url('~assets/fonts/work-sans-v8-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('~assets/fonts/work-sans-v8-latin-600.woff2') format('woff2'),
  url('~assets/fonts/work-sans-v8-latin-600.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('~assets/fonts/work-sans-v8-latin-500.woff2') format('woff2'),
  url('~assets/fonts/work-sans-v8-latin-500.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('~assets/fonts/work-sans-v8-latin-700.woff2') format('woff2'),
  url('~assets/fonts/work-sans-v8-latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('~assets/fonts/SpaceGrotesk-Light.woff2') format('woff2'),
  url('~assets/fonts/SpaceGrotesk-Light.woff') format('woff');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('~assets/fonts/SpaceGrotesk-Regular.woff2') format('woff2'),
  url('~assets/fonts/SpaceGrotesk-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('~assets/fonts/SpaceGrotesk-SemiBold.woff2') format('woff2'),
  url('~assets/fonts/SpaceGrotesk-SemiBold.woff') format('woff');
}


@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('~assets/fonts/SpaceGrotesk-Bold.woff2') format('woff2'),
  url('~assets/fonts/SpaceGrotesk-Bold.woff') format('woff');
}