.container {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
}

.MuiPaper-root {
  background-color: $c-block-bg !important;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}