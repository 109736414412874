.float-label {
  display: flex;
  flex-direction: column;
  position: relative;

  > label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(1);
    padding: 0 14px;
    color: $c-input-placeholder;
    pointer-events: none;
    transform-origin: top left;
    transition: all 0.2s ease-out;
    opacity: 1;
    z-index: 1;
  }

  &:focus-within label,
  > .activated {
    color: $c-input-placeholder;
    padding: 0 3px 5px;
    top: 0;
    transform: translate(0px, -100%) scale(0.9);
    opacity: 1;
    z-index: 1;
  }
}