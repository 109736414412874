@import '~styles/main';

.rp-products {
  .main-title {
    font-weight: normal;
    font-size: $c-size-M;
    line-height: 16px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.2px;
    color: $c-black-4;
    padding-left: 9px;
  }

  .products-container {
    display: flex;
    flex-wrap: wrap;

    .product-item {
      margin: 15px;
      width: calc(50% - 30px);
    }
  }
}
